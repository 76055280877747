<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
export default {
  name: "SubscriptionFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        subscription_status: {
          key: "subscription_status",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Paused",
              value: "paused",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
            {
              label: "All",
              value: "all",
            },
          ],
          value: [],
          searchable: false,
        },
        service: {
          key: "service",
          label: "Services",
          type: "list",
          options: [],
          value: [],
        },
        amount: {
          key: "amount",
          label: "Amount",
          type: "list",
          options: [
            {
              label: "0 - 20000",
              value: "0-20000",
            },
            {
              label: "20001 - 40000",
              value: "20001-40000",
            },
            {
              label: "40001 - 60000",
              value: "40001-60000",
            },
            {
              label: "60001 - 80000",
              value: "60001-80000",
            },
            {
              label: "80001 - 100000",
              value: "80001-100000",
            },
            {
              label: "100001 - 120000",
              value: "100001-120000",
            },
          ],
          value: [],
          searchable: false,
        },
        created: {
          key: ["created_from_date", "created_to_date"],
          label: "Created on",
          type: "daterange",
          value: [],
        },
        next_charge: {
          key: ["next_charge_from_date", "next_charge_to_date"],
          label: "Next Charge Date",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.setServices();
    },
    setServices() {
      this.properties.service.options = [
        ...this.services.map((service) => {
          return {
            label: this.formatText(service.service_name),
            value: service.service_name.toLowerCase(),
          };
        }),
        {
          label: "All",
          value: "all",
        },
      ];
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.paramsLabel[key] = this.properties[property];
              if (key === "service" && value != "all") {
                params.params[key] = [value[0]];
              } else {
                params.params[key] = value[0];
              }
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        } else if (type === "list") {
          params.params[key] = "all";
          params.paramsLabel[key] = "All";
        }
      });

      console.log(params, "paramss");

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
