<template>
  <div>
    <eden-page-header title="Subscriptions" />
    <eden-page-stats :title="'Overview'" :stats="stats" />
    <div>
      <el-tabs v-model="tabMenu" @tab-click="updateRoute">
        <el-tab-pane label="Subscriptions" name="subscriptions">
          <subscriptions />
        </el-tab-pane>
        <el-tab-pane label="Transactions" name="transactions">
          <transactions />
        </el-tab-pane>
        <el-tab-pane label="Payment Links" name="payment-links">
          <payment-links />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Subscriptions from "@/components/Subscriptions/SubscriptionsPayment/Subscriptions";
import Transactions from "@/components/Subscriptions/Transactions/Transactions";
import PaymentLinks from "@/components/Subscriptions/PaymentLinks/PaymentLinks";

export default {
  name: "SubscriptionsIndex",
  components: {
    Subscriptions,
    Transactions,
    PaymentLinks,
  },
  data() {
    return {
      tabMenu: "subscriptions",
    };
  },
  computed: {
    stats() {
      const summary = this.$store.getters.subscriptions_summary;
      return [
        {
          label: "Subscriptions",
          figure: summary.total_subscriptions,
        },
        {
          label: "Active subscriptions",
          figure: summary.total_active_subscriptions,
        },
        {
          label: "Paused subscriptions",
          figure: summary.total_paused_subscriptions,
        },
        {
          label: "Inactive subscriptions",
          figure: summary.total_inactive_subscriptions,
        },
      ];
    },
  },
  created() {
    const { tab } = this.$route.query;
    if (tab) {
      this.tabMenu = tab;
    } else {
      this.tabMenu = "subscriptions";
    }
  },
  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "customers.index",
        query: { tab: tab.name },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
