<template>
  <el-dialog
    title="Delete Transaction"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          Are you sure you want to delete this transaction for
          <span class="text-bold text-black">{{ transaction.user_email }}</span>
          ?
        </p>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="danger" :loading="deleting" @click="deleteTransaction"
        >Delete</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import transactions from "@/requests/subscriptions/transactions";

export default {
  name: "TransactionDelete",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.deleting = false;
    },
    deleteTransaction() {
      this.deleting = true;

      transactions
        .deleteTxn(this.transaction.id)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
