<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <transaction-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          :disabled="
            loading || !pageData.data.length || totalTransactionsAmount === 0
          "
          @click="exportAction"
        >
          Export
        </el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="setTransactionCommand"
        >
          Add New
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData.data" empty-text="No Transactions" width="100%">
        <el-table-column prop="customerName" width="250">
          <template slot="header">
            <span class="eden-table-header">
              Customer
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <div class="customer-transaction">
              <span
                :class="{
                  failed: scope.row.payment_status === 'failed',
                  reversed: scope.row.payment_status === 'reversed',
                }"
              ></span>
              <p class="text-black">{{ scope.row.user_email }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="medium" width="140">
          <template slot="header">
            <span class="eden-table-header">
              Gateway
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <el-tag type="info">{{
              paymentGateways[scope.row.payment_gateway]
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="payment_type" width="250">
          <template slot="header">
            <span class="eden-table-header">
              Type
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.payment_type">
              {{ paymentTypes[scope.row.payment_type] }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column width="250" prop="services">
          <template slot="header">
            <span class="eden-table-header">
              Services
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.service" class="is-flex is-align-center">
              <el-tag
                v-for="(service, i) in sortList(scope.row.service.split(','))"
                :key="i"
                :type="setServiceType(service)"
                class="eden-service-tag"
                >{{ formatText(setServiceType(service)) }}</el-tag
              >
            </div>
            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column prop="payment_description" width="250">
          <template slot="header">
            <span class="eden-table-header">
              Description
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ formatText(scope.row.payment_description) }}
            </p>
          </template>
        </el-table-column>

        <el-table-column width="200" prop="frequency">
          <template slot="header">
            <span class="eden-table-header">
              Service Frequency
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <ul class="service-list">
              <li v-for="(item, index) in scope.row.break_down" :key="index">
                {{ formatText(item.service.name) }} - {{ item.frequency }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="quantity">
          <template slot="header">
            <span class="eden-table-header">
              Service quantity
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <ul class="service-list">
              <li v-for="(item, index) in scope.row.break_down" :key="index">
                {{ formatText(item.service.name) }} -
                {{
                  item.quantity
                    ? item.quantity + quantityLabels[item.service.name]
                    : "N/A"
                }}
              </li>
            </ul>
          </template>
        </el-table-column>

        <el-table-column width="120" prop="amount">
          <template slot="header">
            <span class="eden-table-header">
              Amount
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>{{ formatPrice(scope.row.amount) }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="nextBillingDate" width="160">
          <template slot="header">
            <span class="eden-table-header">
              Payment Date
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>{{ formatDateAndTime(scope.row.date_paid, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column width="160" prop="amount">
          <template slot="header">
            <span class="eden-table-header">
              Created at
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>{{ formatDateAndTime(scope.row.created_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column width="160" prop="amount">
          <template slot="header">
            <span class="eden-table-header">
              Updated at
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>{{ formatDateAndTime(scope.row.updated_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-eye"
                  :command="{
                    action: 'view-receipt',
                    index: scope.$index,
                    row: scope.row,
                  }"
                  >View receipt</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    index: scope.$index,
                    row: scope.row.customer_invoice.length
                      ? {
                          ...recentInvoice(scope.row.customer_invoice),
                          ...scope.row,
                        }
                      : scope.row,
                  }"
                  >Edit transaction</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="allowAccessFor(['admin', 'gardener', 'gardener_pro'])"
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    index: scope.$index,
                    row: scope.row,
                  }"
                  >Delete transaction</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="pageData.data.length && !hidePagination"
        :from="pageData.from"
        :to="pageData.to"
        :total="pageData.total"
        :current-page.sync="page"
      />
    </template>
    <transaction-export
      :show.sync="showExportCustomers"
      :data-to-export="exportData"
    />
    <transaction-form
      :action="action"
      :show.sync="showTransactionForm"
      :transaction="transaction"
      @success="updateTransactions"
    />
    <transaction-preview
      :show.sync="showTransactionPreview"
      :data="transaction"
      action="view-receipt"
    />
    <transaction-delete
      :show.sync="showTransactionDelete"
      :transaction="transaction"
      @success="removeTransaction"
    />
  </div>
</template>

<script>
import TransactionExport from "@/components/Subscriptions/Transactions/TransactionExport";
import TransactionFilter from "@/components/Subscriptions/Transactions/TransactionFilter";
import TransactionForm from "@/components/Subscriptions/Transactions/TransactionForm";
import * as actions from "@/store/action-types";
import transactions from "@/requests/subscriptions/transactions";
import TransactionPreview from "@/components/Subscriptions/Transactions/Actions/TransactionPreview";
import TransactionDelete from "@/components/Subscriptions/Transactions/Actions/TransactionDelete";

export default {
  name: "Transactions",

  components: {
    TransactionDelete,
    TransactionPreview,
    TransactionForm,
    TransactionExport,
    TransactionFilter,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: {
        from: null,
        to: null,
        total: null,
        data: [],
      },
      paymentTypes: {
        "subscription": "Subscription",
        "subscription_renewal": "Subscription",
        "one-off": "One Off",
        "event": "Event",
        "canteen": "Canteen",
        "third-party": "Third Party",
        "after-sales": "After Sales",
        "checkout": "Checkout",
        "wallet-funded": "Wallet",
        "giftcard-purchase": "Giftcard",
        "reconfiguration": "Subscription",
        "corporate-employee-wallet-topup": "Corporate Employee Wallet Topup",
      },
      paymentGateways: {
        "paystack": "Paystack",
        "flutterwave": "Flutterwave",
        "direct_bank_transfer": "Bank Transfer",
        "paybill": "Paybill",
        "giftcard": "Giftcard",
        "wallet": "Wallet",
        "receipt": "Receipt",
        "postpaid": "Post-Paid",
        "corporate-wallet": "Corporate wallet",
        "reimbursement": "Reimbursement",
      },
      showFilters: false,
      hidePagination: false,
      transaction: {},
      showTransactionForm: false,
      showTransactionPreview: false,
      showTransactionDelete: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      totalTransactionsAmount: 0,
      showTransactionExport: false,
      action: "add",
      exportData: [],
      showExportCustomers: false,
      quantityLabels: {
        beauty: " services per month",
        cleaning: "",
        laundry: " bags",
        meal: " meals per week",
      },
    };
  },
  computed: {
    title() {
      const total = this.pageData.total;
      const result = this.pageData.data.length;
      return `${this.hidePagination ? result : total || 0} Transactions ${
        this.totalTransactionsAmount
          ? "- " + this.formatPrice(this.totalTransactionsAmount)
          : ""
      }`;
    },
  },
  watch: {
    page() {
      if (!this.$store.getters.transactions[this.page]) {
        this.getTransactions();
      } else {
        this.setCurrentPageData();
      }
    },
  },
  created() {
    if (!this.$store.getters.transactions[this.page]) {
      this.getTransactions();
    } else {
      this.setCurrentPageData();
    }
  },
  methods: {
    recentInvoice(invoices) {
      return invoices[invoices.length - 1];
    },
    command(command) {
      this.transaction = { ...command.row };
      this.transaction.index = command.index;
      this.transaction.service =
        this.transaction.service?.toLowerCase().split(",") || [];

      const break_down = this.transaction.break_down;
      if (!break_down.length) {
        this.transaction.break_down = this.transaction.service.map(
          (service) => {
            return {
              service,
              item: null,
              frequency: "",
              service_day: [],
              quantity: 1,
              amount: null,
            };
          },
        );
      } else {
        this.transaction.break_down = break_down.map((service) => {
          return {
            ...service,
            frequency: service.frequency.toLowerCase(),
            service_day: service.service_day ? service.service_day : [],
          };
        });
      }

      switch (command.action) {
        case "edit":
          this.showTransactionForm = true;
          this.action = "edit";
          break;
        case "generate":
          this.showTransactionForm = true;
          this.action = "edit";
          break;
        case "view-receipt":
          this.showTransactionPreview = true;
          break;
        case "delete":
          this.showTransactionDelete = true;
          break;
        default:
          break;
      }
    },
    setCurrentPageData() {
      this.pageData = { ...this.$store.getters.transactions[this.page] };
      this.totalTransactionsAmount = 0;
      this.showFilters = false;
      this.pageData.data.sort((a, b) => (a.customer < b.customer ? -1 : 1));
      this.hidePagination = false;
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    setTransactionCommand() {
      this.transaction = {};
      this.showTransactionForm = true;
      this.action = "add";
    },
    search(query) {
      if (query === "") {
        this.setCurrentPageData();
        return;
      }

      this.loading = true;
      transactions
        .search(query)
        .then((response) => {
          this.pageData.data = response.data.data;
          this.hidePagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      transactions
        .filter(params)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          const filteredData = response.data.data.filter((item) => {
            return (
              item.payment_status !== "reversed" &&
              item.payment_status !== "failed"
            );
          });
          this.pageData.data = filteredData;
          this.showPagination = false;
          this.loading = false;
          this.hidePagination = true;
          this.getTotalTransactionsAmount();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      if (key === "period") {
        delete this.filterParams.params[key];
        delete this.filterParams.paramsLabel[key];
      } else {
        this.filterParams.params[key] = "all";
        this.filterParams.paramsLabel[key] = "All";
      }
      this.filter({
        params: this.filterParams.params,
        paramsLabel: this.filterParams.paramsLabel,
      });
    },
    clearFilters() {
      this.setCurrentPageData();
    },
    exportAction() {
      if (this.hidePagination) {
        this.exportData = this.pageData.data;
      } else {
        this.exportData = [];
      }
      this.showExportCustomers = true;
    },
    getTransactions() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_TRANSACTIONS, this.page)
        .then(() => {
          this.setCurrentPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getTotalTransactionsAmount() {
      this.totalTransactionsAmount = this.pageData.data.reduce(
        (total, transaction) => {
          if (transaction.payment_status !== "failed") {
            return total + transaction.amount;
          } else {
            return total;
          }
        },
        0,
      );
    },
    updateTransactions(transaction) {
      if (transaction === undefined) {
        this.getTransactions();
        return;
      }
      transaction.break_down.forEach((service, index) => {
        transaction.break_down[index] = {
          amount: service.amount,
          service: {
            name: service.service,
          },
          frequency: service.frequency,
          item: service.item,
          home_type: service.home_type,
          service_day: service.service_day,
          quantity: service.quantity,
        };
      });
      this.pageData.data.splice(this.transaction.index, 1, {
        ...this.transaction,
        ...transaction,
      });
      this.pageData.data.sort((a, b) =>
        new Date(a.date_paid).getTime() > new Date(b.date_paid).getTime()
          ? -1
          : 1,
      );
    },
    removeTransaction() {
      this.pageData.data.splice(this.transaction.index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.service-list {
  padding: 0px !important;
  list-style-position: inside;
}
.customer-transaction {
  display: flex;
  align-items: center;

  span {
    height: 8px;
    width: 8px;
    border-radius: 100px;
    margin-right: 16px;
    background: #03a84e;

    &.failed {
      background: #e13135;
    }

    &.reversed {
      background: #ff9d00;
    }
  }
}
</style>
