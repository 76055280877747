<template>
  <div>
    <el-dialog
      title="Export Subscriptions"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields" :max="10">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'email'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportSubscriptions"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import subscriptions from "@/requests/subscriptions/subscription";
export default {
  name: "SubscriptionExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      fields: {
        email: "Email",
        subscription_status: "Subscription Status",
        gardener: "Gardener",
        player_ids: "Player Ids",
        billing_date: "Billing Date",
        created_date: "Created Date",
        amount: "Amount ",
        plan: "Plan",
        cleaningAreas: "Cleaning Areas (for customers with cleaning)",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["email"],
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["email"];
      this.form.format = "";
      this.shouldShow = false;
      this.exporting = false;
    },
    exportSubscriptions() {
      if (this.dataToExport.length) {
        this.formatExportData(this.dataToExport);
      } else {
        this.getExportData();
      }
    },
    getExportData() {
      this.loading = true;
      subscriptions
        .export()
        .then((response) => {
          this.formatExportData(response.data.data);
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    setServiceType(plan) {
      let title = [];
      const services = Object.keys(plan);

      services.forEach((service) => {
        let serviceWithType;
        const item = plan[service].item;
        const frequency = this.frequencyLabel(plan[service].frequency);

        switch (service) {
          case "beauty":
            serviceWithType = `Beauty (${frequency})`;
            break;
          case "cleaning":
            serviceWithType = `Cleaning (${item} - ${frequency})`;
            break;
          case "laundry":
            serviceWithType = `Laundry (${item} - ${frequency})`;
            break;
          case "meal":
            serviceWithType = `Meal (${frequency})`;
            break;
          default:
            break;
        }
        title.push(`${serviceWithType}`);
      });

      return title.length ? title.toString().split(",").join("\n\n") : "-";
    },
    formatExportData(data) {
      try {
        let date = this.formatDateAndTime(new Date(), "do m, y");
        let title = `Subscriptions \nExported at - ${date}`;
        let headers = {
          sn: "S/N",
          customer: "Customer Name",
        };
        let subscriptionData = [];
        this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
        subscriptionData = data.map((subscription, index) => {
          let export_object = {};

          Object.keys(headers).forEach((key) => {
            switch (key) {
              case "sn":
                export_object[key] = index + 1;
                break;
              case "customer":
                export_object[key] = this.formatName(subscription.name);
                break;
              case "email":
                export_object[key] = subscription.customer_email.toLowerCase();
                break;
              case "subscription_status":
                export_object[key] = this.formatText(subscription.status);
                break;
              case "player_ids":
                export_object[key] =
                  subscription?.player_ids?.length === 0
                    ? "-"
                    : subscription.player_ids?.map((id) => {
                        return id;
                      });
                break;
              case "billing_date":
                export_object[key] = this.formatDate(
                  subscription.next_charge_date,
                  "dddd do, m, y",
                );
                break;
              case "created_date":
                export_object[key] = this.formatDate(
                  subscription.created_at,
                  "dddd do, m, y",
                );
                break;
              case "gardener":
                export_object[key] = this.formatText(
                  subscription.gardener_name,
                );
                break;
              case "amount":
                export_object[key] = this.formatPrice(subscription.amount);
                break;
              case "plan":
                export_object[key] = this.setServiceType(subscription.plan);
                break;
              default:
                break;
            }
          });

          return export_object;
        });
        this.exportData = {
          title,
          headers,
          data: subscriptionData,
          fileName: title,
          titleMargin: 44,
          format: this.form.format,
        };
        this.exporting = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
