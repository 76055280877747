<template>
  <div>
    <el-drawer
      :visible.sync="setShow"
      :title="'Generate Payment Link'"
      direction="rtl"
      append-to-body
      @close="close"
    >
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <el-form :model="form" label-position="top" ref="form">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Payment Type"
                  prop="payment_type"
                  :rules="validateField()"
                >
                  <el-radio-group
                    v-model="form.payment_type"
                    class="eden-switch-radio small"
                  >
                    <el-radio-button label="subscription"
                      >Subscription</el-radio-button
                    >
                    <el-radio-button label="one-off">One-off</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Services"
                  prop="service"
                  :rules="validateField()"
                >
                  <el-checkbox-group
                    v-model="form.service"
                    class="eden-custom--checkbox flex pick-service"
                    :min="1"
                  >
                    <el-checkbox
                      v-for="(service, index) in services"
                      :key="index"
                      :label="service.service_name.toLowerCase()"
                      >{{ service.service_name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <payment-breakdown
              :payment-type="form.payment_type"
              :services="form.service"
              :breakdown.sync="form.break_down"
              :amount.sync="form.amount"
            />
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  :label="applyDiscount ? 'Subtotal' : 'Amount to pay'"
                  prop="amount"
                  :rules="validateFigure()"
                >
                  <el-input v-model="form.amount" v-number />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-checkbox
                v-model="applyDiscount"
                label="Apply a pending discount to this payment"
              >
              </el-checkbox>
            </el-row>
            <el-row v-if="applyDiscount" class="mb-2">
              <div class="service-configuration card font-sm">
                <eden-loader v-if="loading" />
                <template v-else-if="pendingDiscount.length">
                  <p>Pending discounts attached to this customer</p>
                  <el-form-item
                    label="Select at least one of the discounts available below"
                  >
                    <el-checkbox-group
                      class="block-check w-100"
                      v-model="discountRedeemed"
                    >
                      <el-checkbox
                        v-for="(value, key, i) in pendingDiscount"
                        :key="i"
                        :label="value"
                      >
                        <span class="is-flex justify-between">
                          <div>
                            {{
                              value.discount.discount_type === "fixed-amount"
                                ? formatPrice(value.discount.discount_value)
                                : value.discount.discount_value + "%"
                            }}
                            off code
                          </div>
                          <div>
                            {{ value.title
                            }}{{
                              value.reward_type === "apple"
                                ? " via Garden"
                                : " code"
                            }}
                          </div>
                        </span>
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </template>
                <template v-else>
                  There are no pending discounts available for this customer.
                </template>
              </div>
            </el-row>
            <el-row v-if="discountRedeemed.length" type="flex">
              <el-col :span="24">
                <el-form-item label="Amount to pay" disabled>
                  <el-input v-model="discountedAmount" v-number />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="el-drawer--footer is-align-center">
        <template v-if="link === ''">
          <el-button plain @click="close">Cancel</el-button>
          <el-button
            type="primary"
            :loading="generating"
            :disabled="link !== ''"
            @click="generate"
            >Generate</el-button
          >
        </template>
        <template v-else>
          <el-row v-if="link !== ''" type="flex" class="w-100 fadeIn">
            <el-col :span="24">
              <div>
                <span>Payment link</span>
                <div class="payment--link">
                  <p>{{ link }}</p>
                  <span @click="copy"
                    ><i class="el-icon-document-copy"></i
                  ></span>
                </div>
              </div>
              <el-input v-model="link" id="link" class="eden-copy-input" />
            </el-col>
          </el-row>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import paymentLinks from "@/requests/subscriptions/payment-links";
import customer from "@/requests/customers/customer";
import PaymentBreakdown from "@/components/Subscriptions/Transactions/Breakdown/PaymentBreakdown";

export default {
  name: "PaymentLinkForm",
  components: { PaymentBreakdown },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      generating: false,
      form: {
        payment_type: "",
        first_name: null,
        last_name: null,
        email: "",
        service: [],
        break_down: [],
        amount: null,
      },
      link: "",
      frequencies: {},
      loading: false,
      applyDiscount: false,
      discountRedeemed: [],
      pendingDiscount: [],
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
    discountedAmount() {
      let amount = this.form.amount;
      if (this.discountRedeemed.length) {
        this.discountRedeemed.forEach((item) => {
          if (item.discount.discount_type === "fixed-amount") {
            amount -= item.discount.discount_value;
          } else {
            amount -= (5 / 100) * this.form.amount;
            // amount = -(item.discount.discount_value/100) * this.form.amount
          }
        });
      }
      return amount;
    },
  },
  watch: {
    applyDiscount() {
      if (this.applyDiscount) {
        this.getPendingDiscounts();
      }
    },
    form: {
      deep: true,
      handler() {
        this.link = "";
      },
    },
    show() {
      if (this.customer.next_billing_date) {
        const subscription = this.customer.next_billing_date;
        const plan = subscription.length ? subscription[0].plan : {};
        if (this.show && plan) {
          const services = Object.keys(plan);
          this.form.payment_type = "subscription";
          this.form.service = services;
          this.form.break_down = services.map((service) => {
            const config = plan[service];
            return {
              service: {
                name: service,
              },
              item: config.item,
              frequency: config.frequency.includes("weekly-")
                ? "weekly"
                : config.frequency,
              service_day: config.service_day,
              home_type: config.home_type,
              quantity: config.qty ?? 1,
              amount: config.amount ?? 0,
            };
          });
          this.updateAmount();
        }
      }
    },
  },
  methods: {
    getPendingDiscounts() {
      this.loading = true;
      customer
        .pendingDiscounts(this.customer.id)
        .then((response) => {
          if (response.data.status) {
            this.pendingDiscount = response.data.data;
            this.loading = false;
            // this.$message.success(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateAmount() {
      this.form.amount = this.form.break_down.reduce((acc, item) => {
        return acc + parseInt(item.amount ? item.amount : "0");
      }, 0);
    },
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
      setTimeout(() => {
        this.form.amount = null;
        this.form.break_down = [];
      }, 1000);
      this.link = "";
      this.applyDiscount = false;
      this.discountRedeemed = [];
    },
    formatPayload() {
      const [first_name = null, last_name = null] = this.customer.name
        ? this.customer.name.split(" ")
        : [];
      const email = this.customer.email?.toLowerCase() || null;

      const { payment_type, service, break_down } = this.form;
      const formattedDiscounts = this.discountRedeemed.map((item) => {
        return {
          id: item.redeemed_reward_id,
          type: item.reward_type,
        };
      });
      let payload = {
        first_name: first_name ? this.formatText(first_name) : null,
        last_name: last_name ? this.formatText(last_name) : null,
        email,
        service: service.toString(),
        break_down,
        payment_type,
        amount: this.discountedAmount,
        payment_gateway: "paystack",
        apply_redeemed_discounts: this.applyDiscount,
        redeemed_discounts: formattedDiscounts,
      };
      payload.break_down = payload.break_down.map((service) => {
        return {
          ...service,
          service: service.service.name,
          frequency:
            payload.payment_type === "one-off" ? "one-off" : service.frequency,
        };
      });

      return payload;
    },
    generate() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const payload = this.formatPayload();
        this.generating = true;

        paymentLinks
          .generate(payload)
          .then((response) => {
            if (response.data.status) {
              const COUNTRY = localStorage
                .getItem("eden-crm-location")
                .toLowerCase();
              if (process.env.NODE_ENV !== "develop") {
                this.link = response.data.data.payment_link;
              } else {
                this.link =
                  COUNTRY == "ng"
                    ? `https://pay-staging.edenlife.${COUNTRY}/pay/` +
                      response.data.data.payment_link
                    : `https://pay-staging.ouredenlife.${COUNTRY}/pay/` +
                      response.data.data.payment_link;
              }
              this.$message.success(response.data.message);
            } else {
              this.$message.error(response.data.message);
            }
            this.generating = false;
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.generating = false;
          });
        return true;
      });
    },
    copy() {
      const link = document.getElementById("link");
      link.select();
      link.setSelectionRange(0, 99999);
      document.execCommand("copy");

      this.$message({
        message: "Payment link copied to clipboard",
        type: "success",
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  color: #57645e;
  font-size: 0.875rem;
}

.payment--link {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #03a84e;
  background: #03a84e10;
  border-radius: 6px;
  padding: 0 12px;
  position: relative;
  margin-top: 10px;

  p {
    line-height: 1;
    font-size: 1rem;
    color: #03a84e;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    position: absolute;
    right: -15px;
    top: 0;
    height: 100%;
    width: 50px;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 1.2rem;
      color: #03a84e;
      font-weight: 600;
    }
  }
}

.eden-copy-input {
  opacity: 0;
}
</style>
