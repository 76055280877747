<template>
  <el-dialog
    title="Change Subscription Status"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Select status"
            prop="status"
            :rules="validateField()"
          >
            <el-radio-group v-model="form.status">
              <el-radio label="active">Active</el-radio>
              <el-radio label="paused">Paused</el-radio>
              <el-radio label="inactive">Inactive</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :disabled="form.status === ''"
        :loading="updating"
        @click="changeStatus"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import subscription from "@/requests/subscriptions/index";

export default {
  name: "SubscriptionStatusChange",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      default: null,
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        status: "",
      },
      updating: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.status = this.status;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.updating = false;
    },
    changeStatus() {
      this.updating = true;

      subscription
        .update(this.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success", this.form.status);
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 2;
}
</style>
