<template>
  <div>
    <el-dialog
      title="Export Subscriptions"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Proceed to select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportSubscriptions"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import transactions from "@/requests/subscriptions/transactions";
export default {
  name: "TransactionExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
      quantityLabels: {
        beauty: " services per month",
        cleaning: "",
        laundry: " bags",
        meal: " meals per week",
      },
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.shouldShow = false;
      this.exporting = false;
    },
    exportSubscriptions() {
      if (this.dataToExport.length) {
        this.formatExportData(this.dataToExport);
      } else {
        this.getExportData();
      }
    },
    getExportData() {
      this.loading = true;
      transactions
        .export()
        .then((response) => {
          this.formatExportData(response.data.data);
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    formatExportData(data) {
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Transactions \nExported at - ${date}`;
      let transactionData = [];
      let headers = {
        sn: "S/N",
        email: "Email",
        paymentChannel: " Channel",
        paymentGateway: "Gateway",
        paymentStatus: "Status",
        services: "Services",
        amount: "Amount",
        breakdown: "Breakdown",
        paymentType: "Type",
        service_frequencies: "Service Frequency",
        quantity: "Quantity",
        datePaid: "Date Paid",
      };
      transactionData = data.map((transaction, index) => {
        return {
          sn: index + 1,
          email: this.formatName(transaction.user_email),
          paymentChannel: transaction.payment_channel.includes("bank")
            ? "Transfer"
            : this.formatText(transaction.payment_channel),
          paymentGateway: transaction.payment_channel.includes("bank")
            ? "Bank"
            : this.formatText(transaction.payment_gateway),
          paymentStatus:
          this.formatText(transaction.payment_status),
          amount: this.formatPrice(transaction.amount),
          breakdown: transaction.break_down.length
            ? transaction.break_down
                .map((service) => {
                  return `${this.formatText(service?.service?.name)} ${
                    service.amount ? ` - ${service.amount}` : "N/A"
                  }`;
                })
                .toString()
            : [],
          service_frequencies: transaction.break_down.length
            ? transaction.break_down
                .map((service) => {
                  return `${this.formatText(service?.service?.name)} ${
                    transaction.payment_type !== "subscription"
                      ? " - One-off"
                      : ` - ${this.formatText(service.frequency)}`
                  }`;
                })
                .toString()
            : [],
          paymentType: transaction.payment_type
            ? this.formatText(transaction.payment_type)
            : "-",

          quantity: transaction.break_down.length
            ? transaction.break_down
                ?.map((service) => {
                  return `${this.formatText(service?.service?.name)} ${
                    service.quantity
                      ? ` - ${service.quantity} ${
                          this.quantityLabels[service?.service?.name]
                        }`
                      : "N/A"
                  }`;
                })
                .toString()
            : [],
          services: transaction.service
            ? this.formatToTitleCase(transaction.service.split(",").join(", "))
            : "",
          datePaid: this.formatDate(transaction.date_paid, "ddd do, m, y"),
        };
      });

      this.exportData = {
        title,
        headers,
        data: transactionData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
