<template>
  <div>
    <template>
      <eden-table-actions :title="title" @search="search">
        <template slot="actions">
          <subscription-filter
            :disabled="loading"
            :clear="filterParams.clear"
            @filter="filter"
          />
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="loading || !pageData.data.length"
            @click="exportAction"
          >
            Export
          </el-button>
        </template>
      </eden-table-actions>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <el-table
          :data="pageData.data"
          empty-text="No Subscriptions"
          width="100%"
        >
          <el-table-column prop="customerName" width="230" sortable>
            <template slot="header">
              <span class="eden-table-header">
                Customer
                <i class="el-icon-bottom"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <div
                class="eden-td-stack"
                :style="{ cursor: 'pointer' }"
                @click="
                  $router.push({
                    name: 'subscriptions.subscription',
                    params: {
                      id: scope.row.id,
                    },
                  })
                "
              >
                <p class="text-black">{{ scope.row.name }}</p>
                <span class="text-faded">
                  {{ formatDeletedEmail(scope.row.customer_email) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="plan">
            <template slot="header"> </template>
            <template slot-scope="scope">
              <div
                v-if="scope.row.customer_email.includes('deleted_account')"
                class="is-flex is-align-center"
              >
                <el-tag type="danger"> Deleted account </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="300" prop="plan">
            <template slot="header">
              <span class="eden-table-header">
                Services
                <i class="el-icon-bottom"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <div v-if="scope.row.plan" class="is-flex is-align-center">
                <el-tag
                  v-for="(service, index) in sortList(
                    Object.keys(scope.row.plan),
                  )"
                  :key="index"
                  :type="setServiceType(service)"
                  class="eden-service-tag"
                  >{{ formatText(service) }}</el-tag
                >
              </div>
              <p v-else>-</p>
            </template>
          </el-table-column>
          <el-table-column width="120" prop="amount">
            <template slot="header">
              <span class="eden-table-header">
                Amount
                <i class="el-icon-bottom"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <p>
                {{ formatPrice(scope.row.amount) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="billing_status">
            <template slot="header">
              <span class="eden-table-header">
                Status
                <i class="el-icon-bottom"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <el-tag :type="setType(scope.row.status)">{{
                formatText(scope.row.status)
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="nextBillingDate">
            <template slot="header">
              <span class="eden-table-header">
                Next Billing Date
                <i class="el-icon-bottom"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <p>{{ formatDate(scope.row.next_charge_date) }}</p>
            </template>
          </el-table-column>
          <el-table-column width="60">
            <template slot-scope="scope">
              <el-dropdown @command="command" class="more">
                <span class="el-dropdown-link">
                  <i class="eden-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="
                      allowAccessFor(['superadmin']) &&
                      !scope.row.customer_email.includes('deleted_account')
                    "
                    icon="eden-icon-edit"
                    :command="{
                      action: 'edit',
                      index: scope.$index,
                      row: scope.row,
                    }"
                    >Edit subscription</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="eden-icon-dashboard"
                    :command="{
                      action: 'info',
                      index: scope.$index,
                      row: scope.row,
                    }"
                    >Subscription details</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="eden-icon-eye"
                    :command="{
                      action: 'view',
                      index: scope.$index,
                      row: scope.row,
                    }"
                    >Billing history</el-dropdown-item
                  >
                  <template
                    v-if="
                      allowAccessFor([
                        'superadmin',
                        'admin',
                        'gardener',
                        'gardener_pro',
                      ])
                    "
                  >
                    <el-dropdown-item
                      icon="eden-icon-feedback"
                      :command="{
                        action: 'status',
                        index: scope.$index,
                        row: scope.row,
                      }"
                      >Update status</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="allowAccessFor(['superadmin', 'admin'])"
                      icon="eden-icon-delete"
                      :command="{
                        action: 'delete',
                        index: scope.$index,
                        row: scope.row,
                      }"
                      >Delete subscription</el-dropdown-item
                    >
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="pageData.data.length && !hidePagination"
          :from="pageData.from"
          :to="pageData.to"
          :total="pageData.total"
          :current-page.sync="page"
        />
      </template>
    </template>
    <subscription-form
      action="edit"
      :show.sync="showSubscriptionForm"
      :subscription="subscription"
      @updated="getSubscriptions"
    />
    <subscription
      :show.sync="showSubscription"
      :subscription="subscription"
      @updated="getSubscriptions"
    />
    <subscription-export
      :show.sync="showExportCustomers"
      :data-to-export="exportData"
    />
    <subscription-status-change
      :show.sync="showSubscriptionStatusChange"
      :id="subscription.id"
      :name="subscription.name"
      :email="subscription.email"
      :status="subscription.status"
      @success="updateStatus"
    />
    <subscription-delete
      :show.sync="showSubscriptionDelete"
      :name="subscription.name"
      :subscription-email="subscription.email"
      @success="removeSubscription"
    />
  </div>
</template>

<script>
import SubscriptionExport from "@/components/Subscriptions/SubscriptionsPayment/SubscriptionExport";
import Subscription from "@/components/Subscriptions/Subscription/Subscription";
import SubscriptionFilter from "@/components/Subscriptions/SubscriptionsPayment/SubscriptionFilter";

import * as actions from "@/store/action-types";
import subscriptions from "@/requests/subscriptions/index";
import SubscriptionDelete from "@/components/Subscriptions/Subscription/SubscriptionDelete";
import SubscriptionStatusChange from "@/components/Subscriptions/Subscription/SubscriptionStatusChange";
import SubscriptionForm from "@/components/Subscriptions/Subscription/SubscriptionForm";

export default {
  name: "Subscriptions",
  components: {
    SubscriptionForm,
    SubscriptionStatusChange,
    SubscriptionDelete,
    Subscription,
    SubscriptionFilter,
    SubscriptionExport,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: {
        from: null,
        to: null,
        total: null,
        data: [],
      },
      hidePagination: false,
      showSubscriptionForm: false,
      showSubscriptionDelete: false,
      showSubscriptionStatusChange: false,
      showSubscription: false,
      subscription: {},
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      exportData: [],
      showExportCustomers: false,
    };
  },
  computed: {
    subscriptions() {
      return this.$store.getters.subscriptions;
    },
    title() {
      const total = this.pageData.total;
      const result = this.pageData.data.length;
      return `${this.hidePagination ? result : total || 0} Subscriptions`;
    },
  },
  watch: {
    page() {
      if (!this.$store.getters.subscriptions[this.page]) {
        this.getSubscriptions();
      } else {
        this.setCurrentPageData();
      }
    },
  },
  created() {
    if (!this.$store.getters.subscriptions[this.page]) {
      this.getSubscriptions();
    } else {
      this.setCurrentPageData();
    }
  },
  methods: {
    setSubscription(data) {
      this.subscription = {};
      this.subscription.customerId = data.customerId;
      this.subscription.name = this.formatName(data.name);
      this.subscription.email = data.customer_email.toLowerCase();
      this.subscription.status = data.status;
      this.subscription.start_date = data.start_date;
      this.subscription.created_at = data.created_at;
      this.subscription.updated_at = data.updated_at;
      this.subscription.plan = {};
      this.subscription.id = data.id;
      this.subscription.plan = data.plan;
      this.subscription.next_charge_date = data.next_charge_date ?? "";
      this.subscription.services = data.plan ? Object.keys(data.plan) : [];
      this.subscription.amount = data.amount ?? 0;
    },
    command(command) {
      this.setSubscription(command.row);
      this.subscription.index = command.index;
      switch (command.action) {
        case "edit":
          this.showSubscriptionForm = true;
          break;
        case "info":
          this.showSubscription = true;
          break;
        case "view":
          this.$router.push({
            name: "subscriptions.history",
            params: { id: command.row.id },
          });
          break;
        case "status":
          this.showSubscriptionStatusChange = true;
          break;
        case "delete":
          this.showSubscriptionDelete = true;
          break;
        default:
          break;
      }
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      subscriptions
        .filter(params)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData.data = response.data.data;
          this.showPagination = false;
          this.loading = false;
          this.hidePagination = true;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    exportAction() {
      if (this.hidePagination) {
        this.exportData = this.pageData.data;
      } else {
        this.exportData = [];
      }
      this.showExportCustomers = true;
    },
    clearFilter(key) {
      if (
        key === "subscription_status" ||
        key === "amount" ||
        key === "service"
      ) {
        this.filterParams.params[key] = "all";
        this.filterParams.paramsLabel[key] = "All";
      } else {
        delete this.filterParams.params[key];
        delete this.filterParams.paramsLabel[key];
      }
      this.filter({
        params: this.filterParams.params,
        paramsLabel: this.filterParams.paramsLabel,
      });
    },
    clearFilters() {
      this.setCurrentPageData();
    },
    setCurrentPageData() {
      this.pageData = { ...this.$store.getters.subscriptions[this.page] };
      this.setTotalSubscriptionsAmount = 0;
      this.hidePagination = false;
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    search(query) {
      if (query === "") {
        this.setCurrentPageData();
        return;
      }

      this.loading = true;
      subscriptions
        .search(query)
        .then((response) => {
          this.pageData.data = response.data.data;
          this.hidePagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getSubscriptions() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_SUBSCRIPTIONS, this.page)
        .then(() => {
          this.setCurrentPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateStatus(status) {
      this.pageData.data[this.subscription.index].status = status;
    },
    updateAmount(subscription) {
      this.pageData.data[this.subscription.index].amount = subscription.amount;
    },
    removeSubscription() {
      this.pageData.data.splice(this.subscription.index, 1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
