<template>
  <div>
    <eden-table-actions :show-search="false" :title="title">
      <template slot="actions">
        <payment-link-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="primary"
          class="ml-10"
          :disabled="loading"
          @click="showGeneratePaymentLink = true"
        >
          Generate Link
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData.data">
        <el-table-column prop="payment_type">
          <template slot="header">
            <span> Payment Type </span>
          </template>
          <template v-slot="scope">
            <el-tag type="info">{{
              formatText(scope.row.payment_type)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template slot="header">
            <span> Service </span>
          </template>
          <template v-slot="scope">
            <p v-if="scope.row.service">
              {{ formatToTitleCase(scope.row.service.split(",").join(", ")) }}
            </p>
            <p v-else>-</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Amount </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.amount) }}</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span> Time </span>
          </template>
          <template v-slot="scope">
            <p>{{ formatDateAndTime(scope.row.created_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="payment_link" width="300">
          <template slot="header">
            <span> Link </span>
          </template>
          <template v-slot="scope">
            <p class="text-primary">{{ scope.row.payment_link }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="copy" width="100">
          <template v-slot="scope">
            <div class="link-actions">
              <div class="visit">
                <a :href="scope.row.payment_link" target="_blank">
                  <i class="el-icon-position"></i>
                </a>
              </div>
              <div class="copy" @click="copyLink(scope.row.payment_link)">
                <i class="el-icon-document-copy"></i>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="pageData.data.length"
        :from="pageData.from"
        :to="pageData.to"
        :total="pageData.total"
        :current-page.sync="page"
      />
    </template>
    <payment-link-form :show.sync="showGeneratePaymentLink" />
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import paymentLinks from "@/requests/subscriptions/payment-links";

import PaymentLinkForm from "@/components/Subscriptions/PaymentLinks/PaymentLinkForm";
import PaymentLinkFilter from "@/components/Subscriptions/PaymentLinks/PaymentLinkFilter";

export default {
  name: "PaymentLinks",
  components: {
    PaymentLinkForm,
    PaymentLinkFilter,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: {
        from: null,
        to: null,
        total: null,
        data: [],
      },
      hidePagination: false,
      linkToCopy: "",
      showGeneratePaymentLink: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {
    title() {
      const total = this.pageData.total;
      const result = this.pageData.data.length;
      return `${this.hidePagination ? result : total || 0} Payment Links`;
    },
  },
  watch: {
    page() {
      if (!this.$store.getters.payment_links[this.page]) {
        this.getPaymentLinks();
      } else {
        this.setCurrentPageData();
      }
    },
  },
  created() {
    if (!this.$store.getters.payment_links[this.page]) {
      this.getPaymentLinks();
    } else {
      this.setCurrentPageData();
    }
  },
  methods: {
    setCurrentPageData() {
      this.pageData = { ...this.$store.getters.payment_links[this.page] };
      this.hidePagination = false;
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      paymentLinks
        .filter(params)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData.data = response.data.data;
          this.hidePagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      this.filterParams.params[key] = "all";
      this.filterParams.paramsLabel[key] = "All";

      const keys = this.filterParams.params;
      let keyArr = Object.keys(keys);
      let allCount = 0;
      for (let item in keyArr) {
        if (keys[keyArr[item]] === "all") {
          allCount++;
        }
      }
      if (allCount != 2) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.setCurrentPageData();
      }
    },
    clearFilters() {
      this.setCurrentPageData();
    },
    getPaymentLinks() {
      this.loading = true;
      this.$store.dispatch(actions.GET_PAYMENT_LINKS, this.page).then(() => {
        this.setCurrentPageData();
        this.loading = false;
      });
    },
    copyLink(value) {
      navigator.clipboard.writeText(value);
      this.$message.success("Link copied to clipboard");
    },
  },
};
</script>

<style scoped lang="scss">
.link-actions {
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 30px;
  }

  .visit {
    i {
      font-size: 1.3rem;
    }
  }

  .copy {
    cursor: pointer;

    i {
      color: #03a84e;
      font-size: 1.1rem;
    }
  }
}
</style>
