<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
export default {
  name: "TransactionFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      properties: {
        payment_type: {
          key: "payment_type",
          label: "Payment Type",
          type: "list",
          options: [
            {
              label: "Subscription",
              value: "subscription",
            },
            {
              label: "One-Off",
              value: "one-off",
            },
          ],
          value: [],
          searchable: false,
        },
        service: {
          key: "service",
          label: "Services",
          type: "list",
          options: [],
          value: [],
        },
        customer_type: {
          key: "customer_type",
          label: "Customer Type",
          type: "list",
          options: [
            {
              label: "All",
              value: "all",
            },
            {
              label: "New",
              value: "new",
            },
          ],
          value: [],
          searchable: false,
        },
        period: {
          key: ["start_date", "end_date"],
          label: "Date Paid",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
    properties() {
      // alert("change");
    },
  },
  methods: {
    getData() {
      this.setServices();
    },
    setServices() {
      this.properties.service.options = this.services.map((service) => {
        return {
          label: this.formatText(service.service_name),
          value: service.service_name.toLowerCase(),
        };
      });
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.paramsLabel[key] = this.properties[property];
              if (key === "service") {
                params.params[key] = [value[0]];
              } else {
                params.params[key] = value[0];
              }
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        } else if (type === "list") {
          params.params[key] = "all";
          params.paramsLabel[key] = "All";
        } else {
          params.params.period = "thismonth";
          params.paramsLabel[key] = "This month";
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
